@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Dubai";
  src: url("../public/assets/fonts/Dubai-Regular.ttf");
}

@layer components {
  .heading-title {
    @apply text-black text-[50px] font-bold font-['Dubai'] leading-snug;
  }
}

@layer components {
  .home-button {
    @apply p-2  text-white font-normal bg-lime-500 rounded-[10px] px-5 py-0 justify-center font-['Dubai'] items-center inline-flex hover:-translate-y-1 transition ease-in-out delay-150;
  }
}

@layer components {
  .circleimg {
    @apply md:w-[382px] md:h-[382px] w-[300px] h-[300px]  rounded-[400px] border-4 border-lime-500 shadow-2xl shadow-lime-200;
  }
}

@layer components {
  .whyus {
    @apply flex-1 h-[120px] md:h-[142px] items-center justify-center  bg-white rounded-[15px]   shadow hover:shadow-xl hover:shadow-lime-200 border border-lime-600 hover:-translate-y-3 transition ease-in-out delay-100;
  }
}

@layer components {
  .small-img {
    @apply md:w-[151px] md:h-[150px] w-[100px] h-[100px] rounded-[300px] shadow shadow-lime-200 border border-lime-500 shadow-2xl  hover:-translate-y-5 transition ease-in-out delay-100;
  }
}

@layer components {
  .service-categories-not-selected {
    @apply p-4 w-full border-2 border-lime-500 text-center text-black text-[14px] font-normal rounded-full  justify-center font-['Dubai'] items-center inline-flex hover:bg-lime-400;
  }
}

@layer components {
  .service-categories-selected {
    @apply p-4 w-full border-2 border-lime-500 text-center text-white bg-lime-700 text-[16px] font-normal rounded-full  justify-center font-['Dubai'] items-center inline-flex hover:bg-lime-400;
  }
}

.whatsapp-button {
  /* Add your Tailwind CSS styles here */
  /* For example: */
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color:transparent;
  color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  transition:  -translate-y-4  ease-in-out delay-100;
}


.navbarcss {
  /* Add your Tailwind CSS styles here */
  /* For example: */
  position: fixed;
  background-color:transparent;
  
}

@layer components {
  .text-content {
    @apply  px-5 md:text-center relative text-neutral-500  font-semibold font-['Dubai'] text-center leading-snug;
  }
}
